module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Engineering at Root","short_name":"Root Engineering","start_url":"/","background_color":"#311c3a","theme_color":"#311c3a","display":"minimal-ui","icon":"./src/assets/images/engineering-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ec47df850894339fae3bf20ae51b2824"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
